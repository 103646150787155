<template>
    <div v-loading="$wait.is('loading.services_types')" class="card">
        <h2 class="mb-8">
            {{ $t('statistics.services_types') }}
        </h2>

        <apexchart type="pie" width="650" :options="chartOptions" :series="series" />
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    components: {
        apexchart: VueApexCharts,
    },

    props: {
        dateRange: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            series:       [],
            chartOptions: {
                legend: {
                    formatter: (seriesName, opts) => [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]],
                },
            },
        };
    },

    watch: {
        dateRange() {
            this.getAll();
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.services_types');

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/statistics/services_types`, {
                    params: {
                        from: this.dateRange.from,
                        to:   this.dateRange.to,
                    },
                });
                this.series = data.data;
                this.chartOptions = {
                    labels: data.labels,
                };
            } finally {
                this.$wait.end('loading.services_types');
                this.$wait.end('loading_date_range.prev');
                this.$wait.end('loading_date_range.next');
            }
        },
    },
};
</script>
